import React from 'react'
import {Link} from 'gatsby'

import Layout from '../components/layout'
import Image from '../components/image'
import SEO from '../components/seo'
import Title from '../components/title';

import {withStyles} from '@material-ui/core/styles';

import img1 from '../images/paczki/1.jpg'
import img2 from '../images/paczki/2.jpg'
import img3 from '../images/paczki/3.jpg'
import img4 from '../images/paczki/4.jpg'
import img5 from '../images/paczki/5.jpg'
import img6 from '../images/paczki/6.jpg'


const styles = {
  header: {fontSize: 24, marginBottom: "0.8em"},
  paragraph: {marginBottom: "2em"},
  text: {fontSize: "12px", textAlign: "justify"},
  li: {margin: "auto"}
};

const Paczki = () => (
  <Layout>
    <SEO title="Paczki"
         keywords={[`Paczki`, `Anglia`, `Paczki Polska Anglia`, `Paczki Anglia`, `UK-TRANS`, `Przewozy Okonek`, `Przewozy-Okonek`, `Przewozy.okonek`, `Przeprowadzki anglia.`]}/>
    <Title>PACZKI I PRZESYŁKI DO ANGLII I POLSKI</Title>
    <div className="container" css={styles.text}>
      <img src={img1} srcSet={`${img1} 958w, ${img2} 300w, ${img3} 768w, ${img4} 480w, ${img5} 320W, ${img6} 240W`}/>
      <div css={styles.header}>
        <div>
          Zajmujemy się kompleksową obsługą przesyłek międzynarodowych z Polski do Anglii i odwrotnie.
        </div>
        <div >Przewozimy:</div>
        <ul>
            <li>paczki</li>
            <li>przeprowadzki</li>
            <li>meble, sofy, łóżka</li>
            <li>sprzet rtv/agd</li>
            <li>materiały budowlane</li>
            <li>okna, drzwi</li>
            <li>rowery, motory</li>
        </ul>
        <div>Oferujemy również zaopatrzenie sklepów.</div>
          <div>Szanujemy czas naszych klientów jak i swój własny, dlatego paczki odbieramy ze
              wskazanego miejsca i dowozimy pod wskazany adres. Przesyłki dostarczamy więc
              „z drzwi do drzwi”.</div>
          <div>Doręczenie paczek następuje w ciągu 3-10 dni roboczych.
              Termin odbioru/ doręczenia ustalamy indywidualnie.
              Zadzwoń lub napisz i zapytaj o szczegóły!</div>
      </div>
    </div>
  </Layout>
);

export default withStyles(styles)(Paczki);